import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FunctionUseId } from "radium-omnibus";

const ModalMain = ({
  open = false,
  setOpen = () => {},
  title = "",
  body = false,
  footer = true,
  renderFooter = false,
  labelCancel = "CANCEL",
  labelSubmit = "SUBMIT",
  onClickSubmit = () => {},
  classNameTitle = "text-2xl font-bold",
  styleModal = {},
  maxWidth = " max-w-6xl",
  onClose = false,
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={
          onClose
            ? () => {
                onClose();
              }
            : () => setOpen(false)
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  "w-full p-12 rounded-3xl " +
                  maxWidth +
                  " transform   bg-white  text-left align-middle shadow-xl transition-all"
                }
              >
                <Dialog.Title
                  as="h3"
                  className="text-32px w-full text-center font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                {body}
                {footer && <div className="h-20"></div>}
                {footer && (
                  <div className="absolute bottom-4  left-1/2 transform -translate-x-1/2 ">
                    <div className="flex  space-x-4 text-24px pt-10">
                      <button
                        onClick={() => {
                          setOpen(false);
                        }}
                        className=" shadow-xl rounded-xl border-scbPrimary text-24px text-scbPrimary p-3 w-130px cursor-pointer"
                      >
                        {labelCancel}
                      </button>
                      <button
                        onClick={() => {
                          onClickSubmit();
                        }}
                        className="bg-scbPrimary rounded-xl shadow-xl text-white p-3 text-24px w-130px cursor-pointer"
                      >
                        {labelSubmit}
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  return (
    <div className="transition-all duration-300 relative">
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        style={{
          opacity: open ? 1 : 0,
          pointerEvents: open ? "all" : "none",
        }}
        className={`${
          open
            ? " fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full  bg-black bg-opacity-50 z-50 "
            : " fixed transform -translate-x-1/2 -translate-y-1/2 w-0 h-0  bg-opacity-0 "
        }  transition-all  duration-300`}
      >
        {open ? (
          <div
            style={styleModal}
            onClick={(e) => {
              e.stopPropagation();
              return;
            }}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 bg-white rounded-lg"
          >
            {title && (
              <div className="flex justify-between items-center p-4">
                <div className={classNameTitle}>{title}</div>
              </div>
            )}
            {body && <div className="p-4">{body}</div>}
            {footer && (
              <div className="absolute bottom-4  left-1/2 transform -translate-x-1/2 ">
                {renderFooter ? (
                  <React.Fragment>renderFooter{}</React.Fragment>
                ) : (
                  <div className="flex text-xs space-x-2">
                    <div
                      onClick={() => {
                        setOpen(false);
                      }}
                      className="border border-violet-800 text-violet-800 p-1 cursor-pointer"
                    >
                      {labelCancel}
                    </div>
                    <div
                      onClick={() => {
                        onClickSubmit();
                      }}
                      className="bg-violet-800 text-white p-1  cursor-pointer"
                    >
                      {labelSubmit}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

export default ModalMain;
