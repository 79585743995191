import api from "./api.main";

const amlxLoginNoCode = () => {
  const redirect_url = encodeURIComponent(`${window.location.origin}/authen/callback`)
  return api.instance.get(`/api/v1/auth/login?redirect_url=${redirect_url}`);
}

const amlxLogin = (code, state, id) => {
  return api.instance.get(`/api/v1/auth/token?code=${code}&state=${state}&id=${id}`);
};

const amlxLogout = (refresh_token) => {
  return api.instance.post(`/api/v1/auth/logout`, { refresh_token });
}

const amlxRefreshToken = (refresh_token) => {
  return api.instance.post(`/api/v1/auth/refresh`, { refresh_token });
}

const amlxUser = () => {
  return api.instance.get(`/api/v1/users/me`);
}

const amlxAuthCallback = () => {
  const encodedUrl = encodeURIComponent(`${window.location.origin}/authen/callback`)
  return api.instance.get(`/api/v1/auth/login?redirect=${encodedUrl}`);
}

const exportedObject = {
  amlxLoginNoCode,
  amlxLogin,
  amlxLogout,
  amlxRefreshToken,
  amlxUser,
  amlxAuthCallback
};

export default exportedObject;
