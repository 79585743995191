import api from "./api.main";

const amlxTrans = (params={})=>{
    return api.instance.get(`/api/v1/amlx_tran`, {
        params,
    });
}


const amlxTransByStatus = (params={})=>{
    return api.instance.get(`/api/v1/amlx_tran_detail`, {
        params,
    });
}


const amlxTransDetail = (params={})=>{
    return api.instance.get(`/api/v1/amlx_tran_detail`, {
        params,
    });
}

const amlxRecoline = (params={})=>{
    return api.instance.get(`/api/v1/amlx_reconcile`, {
        params,
    });
}

const amlxSumary = async (params = {}) => {
    return api.instance.get(`/api/v1/amlx_sum`, {
        params,
    });
};

const amlxMapping = async (params = {}) => {
    return api.instance.get(`/api/v1/amlx_map`, {
        params,
    });
};

const amlxMismatch = async (params = {}) => {
    return api.instance.get(`/api/v1/amlx_mismatch`, {
        params,
    });
};

const exportedObject = {
    amlxTrans,
    amlxTransDetail,
    amlxRecoline,
    amlxSumary,
    amlxMapping,
    amlxMismatch,
    amlxTransByStatus
};

export default exportedObject;