import dayjs from "dayjs";
import { useState } from "react";
import XMLApi from "../../api/xmlTrans";
import useQuery from "../../hooks/useQuery";
import Loading from "../../components/loading/loading";
import BasicDatePicker from "../../components/datePicker/datePicker";
import TableMain from "../../components/table/Table.Main";
import { useNavigate, useSearchParams } from "react-router-dom";
import AMLXError from "../../components/amlxError/amlxError";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";

const XMLtransactionreconcile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDate, setFromDate] = useState(
    searchParams.get("fromDate") ? dayjs(searchParams.get("fromDate")) : dayjs()
  );
  const [toDate, setToDate] = useState(
    searchParams.get("toDate") ? dayjs(searchParams.get("toDate")) : dayjs()
  );
  const [reload, setReload] = useState(0);
  const [sort, setSort] = useState("");
  const navigate = useNavigate();

  const mapSortKey = (key) => {
    if (key === "report_date") return "reportDate";
    if (key === "report_type") return "reportType";
    if (key === "xml_record") return "xmlRecord";
    if (key === "report_record") return "reportRecord";
    if (key === "matched") return "matched";
    if (key === "report_missing") return "reportMissing";
    if (key === "xml_missing") return "xmlMissing";
    return "";
  };

  const [sortDic, setSortDic] = useState({
    report_date: DEFAULT_SORT_TYPE,
    report_type: DEFAULT_SORT_TYPE,
    xml_record: DEFAULT_SORT_TYPE,
    report_record: DEFAULT_SORT_TYPE,
    matched: DEFAULT_SORT_TYPE,
    report_missing: DEFAULT_SORT_TYPE,
    xml_missing: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" });
      setSort({ key: key, order: "DESC" });
    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" });
      setSort({ key: key, order: "ASC" });
    }
  };

  const { data, loading, error } = useQuery(
    XMLApi.xmlInquiry,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [reload, sort],
 
  );

  console.log("data ",data,error)

  const validate = () => {
    if (!fromDate || !toDate) {
      alert("กรุณาเลือกวันที่");
      return false;
    }
    if(!fromDate instanceof Date || isNaN(fromDate)){
      alert("กรุณากรอกวันที่เริ่มต้นให้ถูกต้องตามรูปแบบ");
    }
    if(!toDate instanceof Date|| isNaN(toDate)){
      alert("กรุณากรอกวันที่สิ้นสุดให้ถูกต้องตามรูปแบบ");
    }
    if (fromDate !== toDate &&  dayjs(fromDate).isAfter(dayjs(toDate))) {
      alert("วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด");
      return false;
    }
    setSearchParams({
      fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
      toDate: dayjs(toDate).format("YYYY-MM-DD"),
    });
    setReload(reload + 1);
  };

  const onClickRow = (dataCell, key) => {
    const isCollAllowed = key === "report_missing" || key === "xml_missing";
    if (isCollAllowed) {
      const sourceType = key === "report_missing" ? "REPORT" : "XML";
      navigate("/xmlReportMismatch", {
        state: {
          report_date: dataCell.report_date,
          report_type: dataCell.report_type,

          source_type: sourceType,
          fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(toDate).format("YYYY-MM-DD"),
          fromDateFormated: dayjs(fromDate).format("DD/MM/YYYY"),
          toDateFormated: dayjs(toDate).format("DD/MM/YYYY"),
          // fromDate: dataCell.report_date_raw,
          // toDate: dataCell.report_date_raw,
          // fromDateFormated: dataCell.report_date,
          // toDateFormated:  dataCell.report_date,
        },
      });
    }
  };

  if (loading) return <Loading />;

  return (
    <>
          {error && <AMLXError error={error} />}
    <div className="p-8">
      <div className="flex flex-row justify-between px-0 mb-8">
        <BasicDatePicker
          value={fromDate}
          label="From Transaction Date"
          setValue={setFromDate}
        />
        <BasicDatePicker
          value={toDate}
          setValue={setToDate}
          label="To Transaction Date"
        />
        <button
          onClick={validate}
          className="bg-scbPrimary hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto"
        >
          Search
        </button>
      </div>
      <TableMain
        dic={sortDic}
        sort={sort}
        setSort={onSort}
        onClickRow={onClickRow}
        headers={[
          { label: "Date", key: "report_date" },
          { label: "Report Type", key: "report_type" },
          { label: "# XML Records", key: "xml_record" },
          { label: "# Report Records", key: "report_record" },
          { label: "# Matched", key: "matched" },
          { label: "# XML Missing", key: "xml_missing" },
          { label: "# Report Missing", key: "report_missing" },
        ]}
        dataCells={FormatDataTable(data)}
        colAllowedOnClick={["xml_missing", "report_missing"]}
      />
    </div></>
  );
};

const FormatDataTable = (data = []) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      let object = {
        ...item,
        report_date: dayjs(item.report_date).format("DD/MM/YYYY"),
        report_date_raw: item.report_date
      };
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default XMLtransactionreconcile;
