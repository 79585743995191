import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BasicDatePicker from "../../components/datePicker/datePicker";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import useQuery from "../../hooks/useQuery";
import AMLXAPI from "../../api/amlxTrans";
import TableMain from "../../components/table/Table.Main";
import AMLXError from "../../components/amlxError/amlxError";
import Loading from "../../components/loading/loading";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";

const AMLXTran = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDate, setFromDate] = useState(
    searchParams.get("fromDate") ? dayjs(searchParams.get("fromDate")) : dayjs()
  );
  const [toDate, setToDate] = useState(
    searchParams.get("toDate") ? dayjs(searchParams.get("toDate")) : dayjs()
  );
  const [dateType, setDateType] = useState(searchParams.get("dateType") || "I");
  const [dateTypeCommited, setDateTypeCommited] = useState(searchParams.get("dateType") || "I");

  const [reload, setReload] = useState(0);
  const navigate = useNavigate();
  const [sort, setSort] = useState("");

  const mapSortKey = (key) => {
    if (key === "report_date") return "reportDate";
    if (key === "report_type") return "reportType";
    if (key === "total") return "total";
    if (key === "send") return "send";
    if (key === "report") return "report";
    if (key === "deleted") return "deleted";
    if (key === "incomplete") return "incomplete";
    if (key === "new_count") return "newCount";
    return "";
  };

  const [sortDic, setSortDic] = useState({
    report_date: DEFAULT_SORT_TYPE,
    report_type: DEFAULT_SORT_TYPE,
    total: DEFAULT_SORT_TYPE,
    send: DEFAULT_SORT_TYPE,
    report: DEFAULT_SORT_TYPE,
    deleted: DEFAULT_SORT_TYPE,
    incomplete: DEFAULT_SORT_TYPE,
    new_count: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" });
      setSort({ key: key, order: "DESC" });
    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" });
      setSort({ key: key, order: "ASC" });
    }
  };

  const { data, error, loading } = useQuery(
    AMLXAPI.amlxTrans,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      date_type: dateType,
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [reload, sort],
 
  );

  const validate = () => {
    if (!fromDate || !toDate) {
      alert("กรุณาเลือกวันที่");
      return false;
    }
    if (!fromDate instanceof Date || isNaN(fromDate)) {
      alert("กรุณากรอกวันที่เริ่มต้นให้ถูกต้องตามรูปแบบ");
    }
    if (!toDate instanceof Date || isNaN(toDate)) {
      alert("กรุณากรอกวันที่สิ้นสุดให้ถูกต้องตามรูปแบบ");
    }
    if (fromDate !== toDate &&  dayjs(fromDate).isAfter(dayjs(toDate))) {
      alert("วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด");
      return false;
    }
    setSearchParams({
      fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
      toDate: dayjs(toDate).format("YYYY-MM-DD"),
      dateType,
    });
    setDateTypeCommited(dateType);
    setReload(reload + 1);
  };

  const onClickRow = (dataCell, key) => {
    if (key === "report_date" || key === "report_type" || key === "total")
      return;

    if (key === "new_count") key = "NEW"
    if (key === "deleted") key = "DELETE"


    navigate("/amlxtransactionlistbystatus", {
      state: {
        reportDate: dataCell?.report_date_raw,
        reportType: dataCell?.report_type,
        status: key.toUpperCase(),
        dateType: dateTypeCommited === "I" ? "Import Date" : "Transaction Date",
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      {error && <AMLXError error={error} />}
      <div className="p-8">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <div className="text-gray-600 text-lg my-auto mr-4"> Date Type </div>
          <FormControlLabel
            value="I"
            control={
              <Radio
                onChange={(event) => setDateType(event.target.value)}
                checked={dateType === "I"}
              />
            }
            label={<div className="text-gray-600 my-auto">Import Date</div>}
          />
          <FormControlLabel
            value="T"
            control={
              <Radio
                onChange={(event) => setDateType(event.target.value)}
                checked={dateType === "T"}
              />
            }
            label={<div className="text-gray-600 my-auto">Transaction Date</div>}
          />
        </RadioGroup>
        <div className="flex flex-row justify-between  my-8 ">
          <BasicDatePicker label="From Date" value={fromDate} setValue={setFromDate} />
          <BasicDatePicker
            value={toDate}
            setValue={setToDate}
            label="To Date"
          />
          <div />
          <button
            onClick={validate}
            className="bg-scbPrimary hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto"
          >
            Search
          </button>
        </div>
        <TableMain
          dic={sortDic}
          sort={sort}
          setSort={onSort}
          onClickRow={onClickRow}
          headers={[
            { label: "Date", key: "report_date" },
            { label: "Report Type", key: "report_type" },
            { label: "Total Records", key: "total" },
            { label: "# SEND", key: "send" },
            { label: "# REPORT", key: "report" },
            { label: "# DELETE", key: "deleted" },
            { label: "# INCOMPLETE", key: "incomplete" },
            { label: "# NEW", key: "new_count" },
          ]}
          dataCells={FormatDataTable(data)}
          colAllowedOnClick={["send", "report", "deleted", "incomplete", "new_count"]}
        />
      </div>
    </>
  );
};

const FormatDataTable = (data = []) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      let object = {
        ...item,
        report_date: dayjs(item.report_date).format("DD/MM/YYYY"),
        report_date_raw: item.report_date,
      };
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default AMLXTran;
