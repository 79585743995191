import { Controllers } from "radium-omnibus";
import { ICON_CHEVRON_LEFT, ICON_CHEVRON_RIGHT } from "../../utils/useIcons";

const StylePegination =
  "  md:flex justify-center text-20px p-3 items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded ";

const RenderEachButton = ({ count, value, setValue }) => {
  return (
    <div
      onClick={() => {
        setValue(count);
      }}
      className={`${
        count === value ? " text-white bg-scbPrimary" : ""
      } ${StylePegination}`}
    >
      {count}
    </div>
  );
};

const PaginationMain = ({
  totalRecords,
  totalPage,
  value = 1,
  setValue = () => {},
}) => {

  const previous = ()=>{
    if(value === 1) return;
    setValue(value -1)

  }
  const next = () => {
    if(value === totalPage) return
    setValue(value +1)
  }

  return (
    <div className="flex justify-between mx-8 ">
      <div></div>
      {/* <div className="text-20px my-auto">{count} Records</div> */}
      <div className="flex flex-row">
      <div className="my-auto mx-2 text-base text-gray-500"> {1 + ((value- 1 )* 10)} - {( value === totalPage ?totalRecords :((value- 1 )* 10) +  10)}  of {totalRecords}</div>
      <svg className="cursor-pointer" onClick={()=>{setValue(1)}} fill="#888"  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"/><path d="M24 24H0V0h24v24z" fill="none"/></svg>
        <svg className="cursor-pointer" onClick={previous}  fill="#888" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z"  fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>
        <svg  className="rotate-180 cursor-pointer" onClick={next} fill="#888" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z"  fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>
        <svg className="rotate-180 cursor-pointer" onClick={()=>{setValue(totalPage)}} fill="#888"  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"/><path d="M24 24H0V0h24v24z" fill="none"/></svg>
        {/* <div className=" flex flex-col items-center  text-20px text-gray-500">


          <div className="flex ">
            <button
              onClick={() => {
                setValue(value - 1);
              }}
              disabled={value === 1}
              className={
                "p-1 flex justify-center items-center  cursor-pointer border border-gray-300 mr-2 text-20px rounded-md " +
                (value === 1 ? "opacity-50" : "")
              }
            >
              <ICON_CHEVRON_LEFT />
              Previous
            </button>
            <div className="flex rounded-full ">
              {thisArray?.map((_, i) => {
                return (
                  <RenderEachButton
                    key={i}
                    count={i + 1}
                    value={value}
                    setValue={setValue}
                  />
                );
              })}
            </div>
            <button
              onClick={() => {
                setValue(value + 1);
              }}
              disabled={value === pageCount}
              className=" ml-2 text-20px flex justify-center items-center cursor-pointer border border-gray-300 p-1 pl-3 pr-2 rounded"
            >
              <div className="my-auto"> Next</div>
              <ICON_CHEVRON_RIGHT />
            </button>
          </div>
        </div> */}
        {/* <div className="flex space-x-2">
          <div className="my-auto text-20px ml-2"> Go to Page</div>
          <div className="flex">
            <Controllers
              classNameSuperCore="my-auto flex"
              showError={false}
              showErrorAlert={false}
              fields={[
                {
                  name: "page",
                  type: "number",
                  classNameInput:
                    "w-16 mt-auto border h-8 border-gray-400 rounded",
                  classNameWrapper: "my-auto",
                  limit: pageCount,
                  min: 1,
                },
                {
                  type: "submit",
                  labelSubmit: "Go",
                  classNameSubmit:
                    " text-gray-500 border border-gray-400 text-20px px-4 rounded  my-auto ml-1",
                  classNameCore: "col-span-1",
                  onSubmit: (data) => {
                    if (data?.page > 0 && data?.page <= pageCount) {
                      setValue(data?.page);
                    } else {
                      return;
                    }
                  },
                },
              ]}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PaginationMain;
