import dayjs from "dayjs";
import AMLXAPI from "../../api/amlxTrans";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import LabelValue from "../../components/display/labelValue";
import TableMain from "../../components/table/Table.Main";
import useQuery from "../../hooks/useQuery";
import AMLXError from "../../components/amlxError/amlxError";
import Loading from "../../components/loading/loading";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";

const AMLXMismatch = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { fromDateFormated, toDateFormated, fromDate, toDate, dateType } =
    state;
  const [sort, setSort] = useState("");
  const mapSortKey = (key) => {
    if (key === "report_type") return "reportType";
    if (key === "report_id") return "reportId";
    if (key === "type") return "type";
    if (key === "source_system") return "sourceSystem";
    if (key === "ref_system") return "refSystem";
    return "";
  };

  const [sortDic, setSortDic] = useState({
    report_type: DEFAULT_SORT_TYPE,
    report_id: DEFAULT_SORT_TYPE,
    type: DEFAULT_SORT_TYPE,
    source_system: DEFAULT_SORT_TYPE,
    ref_system: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" });
      setSort({ key: key, order: "DESC" });
    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" });
      setSort({ key: key, order: "ASC" });
    }
  };
  const { data, loading, error } = useQuery(
    AMLXAPI.amlxMismatch,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      date_type: dateType === "Import Date" ? "I" : "T",
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [state, sort]
  );

  if (loading) return <Loading />;

  return (
    <>
      {error && <AMLXError error={error} />}
      <div className="p-8">
        <div className="grid grid-cols-4 gap-8 mb-8">
          <LabelValue label="Date Type" value={dateType} />
          <LabelValue label="From Date" value={fromDateFormated} />
          <LabelValue label="To Date" value={toDateFormated} />
          <div className="flex flex-row justify-end">
            <button
              onClick={() => navigate(-1)}
              className="bg-scbPrimary w-1/2 hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto"
            >
              Back
            </button>
          </div>
        </div>
        <TableMain
          dic={sortDic}
          sort={sort}
          setSort={onSort}
          headers={[
            { label: "ลำดับที่", key: "no" },
            { label: "Report Type", key: "report_type" },
            { label: "Report Id", key: "report_id" },
            { label: "Mismatch Type", key: "type" },
            { label: "Source System", key: "source_system" },
            { label: "System Reference ", key: "ref_system" },
          ]}
          dataCells={FormatDataTable(data)}
        />
      </div>
    </>
  );
};

const FormatDataTable = (data = []) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      let object = {
        ...item,
        //   total: <div className="min-w-[40vw] p-3 px-16 text-left ">
        //         {item?.total}
        //   </div>,
      };
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default AMLXMismatch;
