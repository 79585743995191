import sourceInquiry from "../../api/sourceTrans";
import BasicDatePicker from "../../components/datePicker/datePicker";
import TableMain from "../../components/table/Table.Main";
import useQuery from "../../hooks/useQuery";
import SourceSystemDropdown from "../../components/dropdown/sourceSystemDropdown";
import { useState } from "react";
import dayjs from "dayjs";
import Loading from "../../components/loading/loading";
import AMLXError from "../../components/amlxError/amlxError";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";

const SourceTransactionReconcile = () => {
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [sourceIdDisplay, setSourceIdDisplay] = useState("All");
  const [sourceId, setSourceId] = useState("All");
  const [reload, setReload] = useState(0);
  const [sort, setSort] = useState("");

  const mapSortKey = (key) => {
    if (key === "source_file_type") return "sourceFileType";
    if (key === "source_system") return "sourceSystem";
    if (key === "total_records") return "totalRecords";
    if (key === "no") return "sourceId";
    return "";
  };

  const { data, loading, error } = useQuery(
    sourceInquiry,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      source_id: sourceIdDisplay === "All" ? null : sourceIdDisplay,
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [reload, sort],
 
  );

  const [sortDic, setSortDic] = useState({
    source_file_type: DEFAULT_SORT_TYPE,
    no: DEFAULT_SORT_TYPE,
    source_system: DEFAULT_SORT_TYPE,
    total_records: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" });
      setSort({ key: key, order: "DESC" });
    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" });
      setSort({ key: key, order: "ASC" });
    }
  };

  const validate = () => {
    
    if (!fromDate || !toDate) {
      alert("กรุณาเลือกวันที่");
      return false;
    }
    if(!fromDate instanceof Date || isNaN(fromDate)){
      alert("กรุณากรอกวันที่เริ่มต้นให้ถูกต้องตามรูปแบบ");
    }
    if(!toDate instanceof Date|| isNaN(toDate)){
      alert("กรุณากรอกวันที่สิ้นสุดให้ถูกต้องตามรูปแบบ");
    }
    if (fromDate !== toDate &&  dayjs(fromDate).isAfter(dayjs(toDate))) {
      alert("วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด");
      return false;
    }
    setSourceId(sourceIdDisplay);
    setReload(reload + 1);
  };

  const mappingData = () => {
    let ret = [];
    for (let i = 0; i < data.length; i++) {
      ret.push({
        report_date: dayjs(data[i].report_date).format("DD/MM/YYYY"),
        total_records: data[i].total_records,
        total_send: data[i].total_send,
        total_not_send: data[i].total_not_send,
        total_completed: data[i].total_completed,
        total_rejected: data[i].total_rejected,
        diff: <div className={(data[i].diff !== 0) ? "text-red-500 text-center" : "text-gray-500 text-center"}>
          {data[i].diff}
        </div>
      });
    }
    return ret;
  };

  const mappingDataAll = () => {
    const result = data?.map((item, index) => {
      let object = {
        ...item,
        diff: <div className={item.diff !== 0 ? "text-red-500 text-center" :"text-gray-500 text-center"}>
          {item?.diff}
        </div>,
      }
      return object;
    });
    return result;
  }

  if (loading) return <Loading />;

  return (
    <>
      {error && <AMLXError error={error} />}
      <div className="p-8 w-screen">
        <div className="flex flex-row justify-between px-0 mb-8">
          <BasicDatePicker value={fromDate} setValue={setFromDate} />
          <BasicDatePicker
            value={toDate}
            setValue={setToDate}
            label="ถึงวันที่ข้อมูล"
          />
          <SourceSystemDropdown
            value={sourceIdDisplay}
            setSource={setSourceIdDisplay}
          />
          <button
            onClick={validate}
            className="bg-scbPrimary hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto"
          >
            Search
          </button>
        </div>
        <TableMain
          dic={sortDic}
          sort={sort}
          setSort={onSort}
          headers={sourceId === "All" ? headersAll : headSpecific}
          dataCells={sourceId === "All" ? mappingDataAll() : mappingData()}
          footer={
            sourceId === "All" ? (
              <div />
            ) : (
              <tfoot className="border-t border-gray-300">
                <tr>
                  <td className="py-3  px-6 text-left text-lg font-semibold text-gray-700 ">
                    Total
                  </td>
                  <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">
                    {data?.reduce((a, b) => a + b.total_records, 0)}
                  </td>
                  <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">
                    {data?.reduce((a, b) => a + b.total_send, 0)}
                  </td>
                  <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">
                    {data?.reduce((a, b) => a + b.total_not_send, 0)}
                  </td>
                  <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">
                    {data?.reduce((a, b) => a + b.total_completed, 0)}
                  </td>
                  <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">
                    {data?.reduce((a, b) => a + b.total_rejected, 0)}
                  </td>
                  <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">
                    {data?.reduce((a, b) => a + b.diff, 0)}
                  </td>
                </tr>
              </tfoot>
            )
          }
        />
      </div>
    </>
  );
};

const headersAll = [
  { label: "ลำดับที่", key: "no" },
  { label: "Source File Type", key: "source_file_type" },
  { label: "Source System", key: "source_system" },
  { label: "Total Records", key: "total_records" },
  { label: "Total Send", key: "total_send" },
  { label: "Total Not Send", key: "total_not_send" },
  { label: "Total Complete", key: "total_completed" },
  { label: "Total Rejected", key: "total_rejected" },
  { label: "Diff", key: "diff" },
];

const headSpecific = [
  { label: "วันที่ข้อมูล", key: "report_date" },
  { label: "Total Records", key: "total_records" },
  { label: "Total Send", key: "total_send" },
  { label: "Total Not Send", key: "total_not_send" },
  { label: "Total Complete", key: "total_completed" },
  { label: "Total Rejected", key: "total_rejected" },
  { label: "Diff", key: "diff" },
];

export default SourceTransactionReconcile;
