import dayjs from "dayjs";
import useQuery from "../../hooks/useQuery";
import { useState } from "react";
import rejectInquiry from "../../api/rejectTrans";
import Loading from "../../components/loading/loading";
import BasicDatePicker from "../../components/datePicker/datePicker";
import SourceSystemDropdown from "../../components/dropdown/sourceSystemDropdown";
import TableMain from "../../components/table/Table.Main";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import AMLXError from "../../components/amlxError/amlxError";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";

const RejectTransaction = () => {
  const [fromDate, setFromDate] = useState(dayjs())
  const [toDate, setToDate] = useState(dayjs())
  const [sourceId, setSourceId] = useState("All")
  const [sourceIdDisplay, setSourceIdDisplay] = useState("All")
  const [rejectType, setRejectType] = useState("EDW")
  const [reload, setReload] = useState(0)
  const [sort, setSort] = useState("")

  const mapSortKey = (key) => {
    if (key === "source_system") return "sourceSystem"
    if (key === "total_records") return "totalRecords"
    if( key === "report_date") return "reportDate"
    if (key === "no") return "sourceId"
    return ""
  }

  const [sortDic, setSortDic] = useState({
    source_system: DEFAULT_SORT_TYPE,
    total_records: DEFAULT_SORT_TYPE,
    no: DEFAULT_SORT_TYPE,
    report_date:DEFAULT_SORT_TYPE
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" })
      setSort({ key: key, order: "DESC" })

    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" })
      setSort({ key: key, order: "ASC" })
    }
  }


  const validate = () => {
    if (!fromDate || !toDate) {
      alert("กรุณาเลือกวันที่")
      return false
    }
    if(!fromDate instanceof Date || isNaN(fromDate)){
      alert("กรุณากรอกวันที่เริ่มต้นให้ถูกต้องตามรูปแบบ");
    }
    if(!toDate instanceof Date|| isNaN(toDate)){
      alert("กรุณากรอกวันที่สิ้นสุดให้ถูกต้องตามรูปแบบ");
    }
    if (fromDate !== toDate &&  dayjs(fromDate).isAfter(dayjs(toDate))) {
      alert("วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด")
      return false
    }
    setSourceId(sourceIdDisplay)
    setReload(reload + 1)

  }
  const { data, loading, error } = useQuery(
    rejectInquiry,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      reject_type: rejectType,
      source_id: sourceIdDisplay === "All" ? null : sourceIdDisplay,
      ...(sort?.key && ((sourceIdDisplay === "All" && sort?.key !== "report_date") ||sourceIdDisplay !== "All") &&  { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [reload, sort],
 
  );

  if (loading) return <Loading />


  return <>
    {error && <AMLXError error={error} />}
    <div className="p-8">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <div className="text-gray-600 text-lg my-auto mr-4"> Reject Type </div>
        <FormControlLabel value="EDW" control={<Radio onChange={(event) => setRejectType(event.target.value)} checked={rejectType === 'EDW'} />} label={<div className="text-gray-600 my-auto">Reject by EDW (Not Send)
        </div>} />
        <FormControlLabel value="AMLX" control={<Radio onChange={(event) => setRejectType(event.target.value)} checked={rejectType === 'AMLX'} />} label={<div className="text-gray-600 my-auto">Reject by AMLX</div>} />
      </RadioGroup>
      <div className="flex flex-row justify-between  my-8 ">
        <BasicDatePicker value={fromDate} setValue={setFromDate} />
        <BasicDatePicker value={toDate} setValue={setToDate} label="ถึงวันที่ข้อมูล" />
        <SourceSystemDropdown value={sourceIdDisplay} setSource={setSourceIdDisplay} />
        <button onClick={validate} className="bg-scbPrimary hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto">Search</button>
      </div>

      <TableMain
        dic={sortDic} sort={sort} setSort={onSort}
        headers={sourceId === "All" ? headersAll : headSpecific}
        dataCells={ProcessFormatDataTable(data)}
        footer={sourceId === "All" ? <div /> :
          <tfoot className="border-t border-gray-300">
            <tr>
              <td className="py-3  px-6 text-left text-lg font-semibold text-gray-700 ">Total</td>
              <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">{data?.reduce((a, b) => a + b.total_records, 0)}</td>
              <td className="py-3  px-6 text-left "></td>
              <td className="py-3  px-6 text-center text-lg font-semibold text-gray-700 ">{data?.reduce((a, b) => a + b.reasons.reduce((a, b) => a + b.total, 0), 0)}</td>
            </tr>
          </tfoot>}
      />
    </div>
  </>
}


const headersAll = [{ label: "ลำดับที่", key: "no" },
{ label: "Source System", key: "source_system" },
{ label: "Total Rejected", key: "total_records" },
{ label: "Reason", key: "reasons" },
{ label: "No. of Records", key: "no_of_rec" },]

const headSpecific = [{ label: "Date", key: "report_date" },
{ label: "Total Rejected", key: "total_records" },
{ label: "Reason", key: "reasons" },
{ label: "No. of Records", key: "no_of_rec" },]

const ProcessFormatDataTable = (
  data = [],
  navigate,
  isSelect = false,
  setDataTable
) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {

      let object = {
        ...item, report_date: dayjs(item.report_date).format("DD/MM/YYYY"),
        no_of_rec:<div className="text-right py-3 px-6">{item.no_of_rec}</div>,
        reasons: <div className="min-w-[500px] p-3 px-0 ">{
          item?.reasons?.map((reason, index) => {
            return <div key={`key-of-reason-${index}`} className="flex flex-row">
              <div className="w-full">{reason?.reason}</div>
            </div>
          })}</div>,
        no_of_rec: <div className="p-3 px-0 ">{
          item?.reasons?.map((reason, index) => {
            return <div key={`key-of-reason-${index}`} className="flex flex-row">
              <div className="w-full text-center">{reason?.total}</div>
            </div>
          })}</div>
      }
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default RejectTransaction;
