import { useLocation } from "react-router-dom";
import NavbarModalUser from "./render/Navbar.ModalUser";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import userStore from "../../store/userStore";

const NameFromPathLocation = (path) => {
  if(path === "/sourcetransactionreconcile") return "Source Transactions Reconcile Report "
  if(path === "/rejectedsourcetransaction") return "Rejected Transactions Report"
  if(path === "/amlxtransactionreconcile") return "AMLX Transactions Reconcile Report"
  if(path === "/amlxtransactionmapping") return "AMLX Transactions Mapping"
  if(path === "/amlxtransactionmismatched") return "Mismatched AMLX Transactions"

  if(path === "/amlxtransactionreport") return "AMLX Transactions Report"
  if(path === "/amlxtransactionlistbystatus") return "Transactions List by Status Report"

  if(path === "/xmltransactionreconcile") return "XML Transactions Report"
  if(path === "/xmlReportMismatch") return "Mismatched XML Transactions Report"
  return "AMLO RECONCILE REPORT"
}

const NavbarMain = () => {
  const location = useLocation();
  const wrapper = useRef(null)
  const navigate = useNavigate();
  const [showOptions, setshowOptions] = useState(false)
  const user = userStore.getState();
  const toogleOptions = () => {
    setshowOptions(!showOptions)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setshowOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapper);


  if (location.pathname === "/login") return null;
  
  return (
    <div className="flex justify-between px-90px h-142px bg-scbPrimary">
      <div className="flex flex-row">
        <img src="/images/logo.png" className="h-51px my-auto" alt="user" />
        <div className="h-full flex flex-col justify-center">
          <div className="text-white font-bold text-46px ml-4  font-sans">
            SCB
          </div>
          <div className="text-white  font-semibold text-[24px] ml-8 -mt-2  font-sans">
            AMLX
          </div>
        </div>
        <div onClick={toogleOptions} className="cursor-pointer ml-8 relative h-full flex flex-col justify-center text-[28px] px-8 py-1 text-[#FFD700]">AMLO RECONCILE REPORT
          <div className="text-xl text-white ">{NameFromPathLocation(location.pathname)}</div>
          <div ref={wrapper} className={showOptions ? "z-50 text-white  absolute -bottom-[190px] w-full border border-gray-50 rounded-lg shadow-lg bg-white" : "hidden "}>
            <div onClick={()=>navigate("/sourcetransactionreconcile")} className="px-4 py-2 text-black hover:bg-gray-100 text-lg">Source Transactions Reconcile</div>
            <div  onClick={()=>navigate("/rejectedsourcetransaction")} className="px-4 py-2 text-black hover:bg-gray-100 text-lg">Rejected Transactions Report</div>
            <div onClick={()=>navigate("/amlxtransactionreconcile")}  className="px-4 py-2 text-black hover:bg-gray-100 text-lg">AMLX Transactions Reconcile</div>
            <div onClick={()=>navigate("/amlxtransactionreport")}  className="px-4 py-2 text-black hover:bg-gray-100 text-lg">AMLX Transactions Report</div>
            <div onClick={()=>navigate("/xmltransactionreconcile")} className="px-4 py-2 text-black hover:bg-gray-100 text-lg">XML Transactions Reconcile</div>

          </div>
        </div>
      </div>


      <NavbarModalUser
        lastLogin={user?.user?.last_login}
        name={user?.user?.user_name || "-"}
        oc={user?.user?.oc || "-"}
        version={"1.0.29"}
      />
    </div>
  );
};

export default NavbarMain;
