import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker({ label = "จากวันที่ข้อมูล", value, setValue }) {
    // const [cleared, setCleared] = React.useState(false)

    const onChange = (newValue) => {
        setValue(newValue)
        // value={value}
        // onChange={(newValue) => setValue(newValue)}
    }



    return (
        <div className='flex flex-row space-x-2'>
            <div className='my-auto text-xl text-gray-600'>{label}</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    onChange={onChange}
                    value={value}
                    format='DD/MM/YYYY'
                    sx={{ width: 260, height: 56 }}

                    slotProps={{
                        textField: {
                            error: value === null ,
                          },
                        field: { clearable: true },
                    }} />
            </LocalizationProvider>
        </div>
    );
}
