import axios from "axios";
// import store from "../redux/store";
// import { setModal } from "../redux/modalSlice";
// import { apiBaseURL, modalConfig } from "../config/config.main";
// import { StatusCodes } from "http-status-codes";
//import { getTokenFromLocalStorage } from "../utils/util.main";
// import authService from "../api/authService";

const instance = axios.create({
  baseURL: ""
});

const requestInterceptors = (configAxios) => {
  const access_token = sessionStorage.getItem("access_token");
  const refresh_token = sessionStorage.getItem("refresh_token");

  if (configAxios.url !== "/v1/auth/logout") {
    if (access_token) {
      configAxios.headers = {
        ...configAxios.headers,
        Authorization: `Bearer ${access_token}`,
      };
    }
  } else {
    if (refresh_token) {
      configAxios.headers = {
        ...configAxios.headers,
        Authorization: `Bearer ${refresh_token}`,
      };
    }
  }
  return configAxios;
};

const responseInterceptors = (response) => {
  return response;
};

const responseErrorInterceptors = async (error) => {
  const errorResponse = error?.response || { response: "Network Error" };

  if (errorResponse?.status === 401) {
    //  const token = getTokenFromLocalStorage();
    const token = "";

    if (
      token.refresh_token !== undefined &&
      token.refresh_token !== null &&
      token.refresh_token !== "" &&
      token.refresh_token
    ) {
      // alert(errorResponse?.data?.status?.description);
      sessionStorage.clear();
      // store.dispatch(setModal({
      //   open: true,
      //   ...modalConfig.sessionExpiredModal,
      // }));
      // window.location.reload()
      // await  authService.logout({ refresh_token: token.refresh_token });
    } else if (errorResponse?.data?.status?.code !== undefined) {
      // console.log("IN2")
      // alert(errorResponse?.data?.status?.description);

      sessionStorage.clear();
      // store.dispatch(setModal({
      //   open: true,
      //   ...modalConfig.sessionExpiredModal,
      // }));
      //  await  authService.logout({ refresh_token: token.refresh_token });
    } else {
      window.location.replace("/login");
      sessionStorage.clear();
      // store.dispatch(setModal({
      //   open: true,
      //   ...modalConfig.sessionExpiredModal,
      // }));
      // await authService.logout({ refresh_token: token.refresh_token });
    }
  } else if (errorResponse?.status === 404) {
    console.error("NOT_FOUND");
  } else if (errorResponse?.status >= 400) {
    // alert(errorResponse?.data?.description);
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(requestInterceptors);

instance.interceptors.response.use(
  responseInterceptors,
  responseErrorInterceptors
);

const exportedObject = { instance };

export default exportedObject;
