import { useState, Fragment } from "react";
import BreadCrumbMain from "../../components/breadcrumb/BreadCrumb.Main";
import HomeCard from "./render/Home.Card";
import { Dialog, Transition } from "@headlessui/react";

const HomeMain = ({ CARDS }) => {
  const [showPopup, setShowPopup] = useState(false);
  return <div></div>;

  return (
    <div className="px-90px">
      <BreadCrumbMain />
      <div className="grid grid-cols-5 mx-auto max-w-7xl gap-6">
        {CARDS?.map((card, index) => {
          return (
            <HomeCard
              key={`key-of-card-${index}`}
              image={card?.image}
              name={card?.name}
              path={card?.path}
              disabled={card?.disabled}
            />
          );
        })}
      </div>
      <Transition appear show={showPopup} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowPopup(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden  bg-white  text-left align-middle shadow-xl transition-all">
                  <img src="/images/mainpopup.png"></img>
                  <div
                    onClick={() => setShowPopup(false)}
                    className="absolute cursor-pointer top-0 right-4 font-light text-38px text-textGrey"
                  >
                    X
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HomeMain;
