import Lottie from "lottie-react";

import * as animationData from '../../loading.json'

const Loading = () => {
    return <div className='w-screen h-full flex flex-col justify-center'>
        <Lottie  rendererSettings={
            {
                preserveAspectRatio: 'xMidYMid slice'
            }
        } 
        style={{ height: 360,width:360, alignItems: 'center',margin:'auto' }}
        animationData={animationData} loop={true} />
        {/* <Lottie width={300} height={300} options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }} */}
        </div>

}

export default Loading;