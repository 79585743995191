import { MenuItem, Select } from "@mui/material";
import Loading from "../loading/loading";
import masterDropdown from "../../api/dropdownApi";
import useQuery from "../../hooks/useQuery";

const SourceSystemDropdown = ({ setSource,value = "All" }) => {

    /// connect to api
    const onChange = (e) => {
        setSource(e.target.value)
    }

    const { data, loading, error } = useQuery(masterDropdown, {}, {}, []);

    if (loading) return <Loading />

    return (<div className='flex flex-row space-x-2'>
        <div className='my-auto text-xl text-gray-600'>Source System</div>
        <Select onChange={onChange} sx={{ width: 260 }} defaultValue={value}
        >
            <MenuItem value={"All"}>All</MenuItem>
            {data.map((item) =>
                <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>)
            }
        </Select>
    </div >
    )
}

export default SourceSystemDropdown;