import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function AuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (query.get("code")) {
      sessionStorage.setItem("token-login", query.get("code"));
      sessionStorage.setItem("token-state", query.get("state"));
      navigate("/login");
    }
  }, []);

  return null;
}

export default AuthCallback;
