import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AMLXAPI from "../../api/amlxTrans"
import useQuery from "../../hooks/useQuery";
import dayjs from "dayjs";
import LabelValue from "../../components/display/labelValue";
import TableMain from "../../components/table/Table.Main";
import Loading from "../../components/loading/loading";
import AMLXError from "../../components/amlxError/amlxError";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";
import PaginationMain from "../../components/pagination/Pagination.Main";

const ITEM_PER_PAGE = 10

const AMLXTransactionMapping = () => {
  const [page, setPage] = useState(1)
  const { state } = useLocation();
  const navigate = useNavigate();
  const { fromDateFormated, toDateFormated, fromDate, toDate, reportType, sourceId, sourceIdRaw, dateType, total, unique } = state;
  const [sort, setSort] = useState("")

  const mapSortKey = (key) => {
    if (key === "report_id") return "reportId"
    if (key === "total") return "total"
    return ""
  }

  const [sortDic, setSortDic] = useState({
    report_id: DEFAULT_SORT_TYPE,
    total: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" })
      setSort({ key: key, order: "DESC" })

    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" })
      setSort({ key: key, order: "ASC" })
    }
  }

  const { data, loading, error, pagination } = useQuery(
    AMLXAPI.amlxMapping,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      date_type: dateType === "Import Date" ? "I" : "T",
      report_type: reportType,
      source_id: sourceIdRaw,//sourceId,
      pageIndex: page,
      recordPerPage: ITEM_PER_PAGE,
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [state, sort, page]
  );

  if (loading) return <Loading />

  return <>
    {error && <AMLXError error={error} />}
    <div className="p-8">
      <div className="grid grid-cols-4 gap-8 mb-8">
        <LabelValue label="Date Type" value={dateType} />
        <LabelValue label="From Date" value={fromDateFormated} />
        <LabelValue label="To Date" value={toDateFormated} />
        <LabelValue label="Source System" value={sourceId} />
        <LabelValue label="Report Type" value={reportType} />
        <LabelValue label="Total Records" value={total} />
        <LabelValue label="Unique Records" value={unique} />
        <div className="flex flex-row justify-end">
          <button onClick={() => navigate(-1)} className="bg-scbPrimary w-1/2 hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto">Back</button>
        </div>
      </div>
      <TableMain
        dic={sortDic} sort={sort} setSort={onSort}
        headers={[{ label: "ลำดับที่", key: "no" },
        { label: "Report Id", key: "report_id" },
        { label: "#Source Records", key: "total" }]} dataCells={FormatDataTable(data)}
      />
      <div className="h-6"></div>
      {pagination?.total_pages > 1 &&

        <PaginationMain
          totalRecords={pagination?.total_records}
          value={page}
          totalPage={pagination?.total_pages}
          setValue={setPage}
        />
      }
    </div>
  </>
}

const FormatDataTable = (
  data = [],
) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      let object = {
        ...item,
        total: <div className="min-w-[40vw] p-3 px-16 text-left ">
          {item?.total}
        </div>,
      }
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default AMLXTransactionMapping;