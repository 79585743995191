import api from "./api.main";

const xmlInquiry = async (params = {}) => {
    return api.instance.get(`/api/v1/xml_tran`, {
        params,
    });
};

const xmlReport = async (params = {}) => {
    return api.instance.get(`/api/v1/xml_mismatch`, {
        params,
    });
};

const exportedObject = {
    xmlInquiry,
    xmlReport
  
};

export default exportedObject;