import { useEffect } from "react";
import userStore from "../store/userStore";
import { useNavigate } from "react-router-dom";

function ProtectRoute({ children }) {
  const { user } = userStore.getState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  if (!user) return null

  return children;
}

export default ProtectRoute;
