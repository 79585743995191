export const ICON_ARROW_DOWN = ({ onClick, className, fill = "none" }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path fill="currentColor" d="m12 15l-5-5h10Z" />
    </svg>
  );
};

export const ICON_CHEVRON_LEFT = ({
  onClick,
  className = "w-6 h-6 text-gray-500",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

export const ICON_CHEVRON_RIGHT = ({
  onClick,
  className = "w-6 h-6 text-gray-500",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

export const ICONS_CHEVRON_DOWN = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

export const ICONS_CHEVRON_UP = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
    </svg>
  );
};
