import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading";
import XMLApi from "../../api/xmlTrans";
import { useState } from "react";
import useQuery from "../../hooks/useQuery";
import dayjs from "dayjs";
import TableMain from "../../components/table/Table.Main";
import LabelValue from "../../components/display/labelValue";
import AMLXError from "../../components/amlxError/amlxError";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";
import PaginationMain from "../../components/pagination/Pagination.Main";

const ITEM_PER_PAGE = 10

const XMLReportMisMatch = () => {
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("")
  const { state } = useLocation();
  const navigate = useNavigate();
  const { fromDateFormated, toDateFormated, fromDate, toDate,report_type,source_type } = state;

  const mapSortKey = (key) => {
    if (key === "report_date") return "reportDate"
    if (key === "report_type") return "reportType"
    if (key === "report_id") return "reportId"
    if (key === "type") return "type"
    return ""
  }

  const [sortDic, setSortDic] = useState({
    report_date: DEFAULT_SORT_TYPE,
    report_type: DEFAULT_SORT_TYPE,
    report_id: DEFAULT_SORT_TYPE,
    type: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" })
      setSort({ key: key, order: "DESC" })

    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" })
      setSort({ key: key, order: "ASC" })
    }
  }


  const { data, loading, pagination, error } = useQuery(
    XMLApi.xmlReport,
    {
      // report_date: dayjs(report_date).format("YYYY-MM-DD"),
      from_date: fromDate,
      to_date: toDate,
      report_type: report_type,
      source_type:source_type,
      pageIndex: page,
      recordPerPage: ITEM_PER_PAGE,
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [page, sort]
  );

  if (loading) return <Loading />

  return <>
    {error && <AMLXError error={error} />}
    <div className="p-8">
      <div className="flex flex-row justify-between px-32 mb-8">

        <LabelValue label="From Transaction Date" value={fromDateFormated} />
        <LabelValue label="To Transaction Date" value={toDateFormated} />
        <button onClick={() => navigate(-1)} className="bg-scbPrimary hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto">Back</button>
      </div>
      <TableMain
        dic={sortDic} sort={sort} setSort={onSort}
        headers={[{ label: "Date", key: "report_date" },
        { label: "Report Type", key: "report_type" },
        { label: "Report No", key: "report_id" },
        { label: "Mismatch Type", key: "type" }]} dataCells={FormatDataTable(data)}

      />
      <div className="h-6"></div>
      {pagination?.total_pages > 1 &&

        <PaginationMain
          totalRecords={pagination?.total_records}
          value={page}
          totalPage={pagination?.total_pages}
          setValue={setPage}
        />}
    </div>
  </>
}

const FormatDataTable = (
  data = [],
) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      let object = {
        ...item, report_date: dayjs(item.report_date).format("DD/MM/YYYY"),
        type: <div className="min-w-[500px] p-3 px-0 ">
          {item?.type}
        </div>,
      }
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default XMLReportMisMatch;