import React, { useEffect, useState } from "react";
import PaginationMain from "../pagination/Pagination.Main";

const TableMain = ({ dic,page, sort, setSort, totalPage, setPage, headers = [], dataCells = [], onClickRow = null, footer = <div></div> , colAllowedOnClick = []}) => {

  return (
    <div className="overflow-x-scroll  pb-6 ">
      <div className="mb-6  flex items-center justify-center font-sans ">
        <div className="w-full">
          <div className=" ">
            <table className="min-w-max w-full table-auto">
              <thead className="">
                <tr className="bg-gray-100  text-gray-600 font-normal text-lg ">
                  {headers?.map((header, index) => {
                    return (
                      <th
                        key={`key-of-header-${index}`}
                        onClick={() => setSort(header?.key)}
                        className={
                          "py-3  px-6  " +  (dic?.hasOwnProperty(header?.key) ? " cursor-pointer " : "" )
                        }
                      >
                        <div className={"flex flex-row " + ( header.key === "no" ?"justify-center":"")}>
                          <div> {header?.label}
                          </div>
                          <svg className={sort?.key === header?.key ? "w-5 h-5 ml-2 mt-1 "+ (sort?.order === "ASC" ?"":"rotate-180") : "hidden"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                          </svg>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-gray-500 text-base font-light">
                {dataCells?.map((dataCell, index) => {
                  return (
                    <React.Fragment key={`key-of-data-cell-${index}`}>
                      <tr className="border-0 border-gray-200  hover:bg-gray-100">
                        {headers?.map((header, hindex) => {
                          const isShowCursorPointer = colAllowedOnClick.includes(header?.key);
                          return (
                            <th
                              onClick={() => {
                                if (isShowCursorPointer) onClickRow(dataCell, header?.key);
                              }}
                              key={`key-of-cell-${hindex}`}
                              className={"py-3 px-6  " + (isShowCursorPointer ? " cursor-pointer " : "") + ((isNaN(dataCell[header?.key]) || Number(dataCell[header?.key]) !== dataCell[header?.key]) ? " text-left" : "text-center")}
                            >
                              {typeof dataCell[header?.key] === "function"
                                ? dataCell[header?.key]({
                                  dataCell: dataCell,
                                })
                                : dataCell[header?.key]}
                            </th>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
              {footer}

            </table>
          </div>
        </div>
      </div>
 
    </div>
  );
};

export default TableMain;
