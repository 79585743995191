import { DateTime } from "luxon";

export const FunctionChangeSnakeCaseToNormalCase = (string) => {
  const first = string?.slice(0, 1).toLocaleUpperCase();
  const last = string.replace(/([-_][a-z])/g, (group) => {
    return group.replace("-", " ").replace("_", " ");
  });
  return first + last.slice(1);
};

export const parseJwt = (accessToken) => {
  // Split the JWT into its three parts
  const [header, payload, signature] = accessToken.split(".");

  // Decode the base64-encoded header and payload
  const decodedHeader = JSON.parse(atob(header));
  const decodedPayload = JSON.parse(atob(payload));

  // Return the JSON representation of the decoded header and payload
  return {
    ...decodedHeader,
    ...decodedPayload,
  };
};

export const getExpiredDate = (expires_in) => {
  return Date.now() + expires_in * 1000;
};

export const saveTokenToLocalStorage = (data) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("expired_at", getExpiredDate(data.expires_in));
  sessionStorage.setItem(
    "refresh_expired_at",
    getExpiredDate(data.refresh_expires_in)
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at",
    getExpiredDate(data.expires_in) - 1000 * 60 * 2
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at_date",
    DateTime.fromJSDate(
      new Date(getExpiredDate(data.expires_in) - 1000 * 60 * 2)
    ).toFormat("dd-MM-yyyy HH:mm:ss")
  );
};
