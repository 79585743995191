import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import NavbarMain from "./components/navbar/Navbar.Main";
import LoginMain from "./pages/login/Login.Main";
import HomeMain from "./pages/home/Home.Main";
import SourceTransactionReconcile from "./pages/sourcetransactionreconcile/SourceTransactionReconcile.Main";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import RejectTransaction from "./pages/rejectTransaction/RejectTransaction";
import XMLtransactionreconcile from "./pages/xmlTransaction/xmlTransactionMain";
import XMLReportMisMatch from "./pages/xmlTransaction/xmlReportMissMatch";
import AMLXTransaction from "./pages/amlxTransaction/amlxTransaction";
import AMLXTransactionMapping from "./pages/amlxTransaction/amlxTransactionMapping";
import AMLXMismatch from "./pages/amlxTransaction/amlxMismatch";
import AMLXTran from "./pages/amlxTransactionReport/amlxTran";
import AMLXTransactionByStatus from "./pages/amlxTransactionReport/amlxTranByStatus";
import ProtectRoute from "./routes/protectRoute";
import userStore from "./store/userStore";
import AuthCallback from "./pages/authCallback/AuthCallback.main";
import RefreshToken from "./components/refreshToken/refreshToken.Main";

const CARDS = [
  {
    image: "/images/process.png",
    name: "PROCESS CREATE AND MAINTENANCE",
    path: "/process_create_and_maintenance/summary",
    disabled: false,
  },
];

const routes = [
  {
    path: "/",
    element: (
      <ProtectRoute>
        <HomeMain CARDS={CARDS} />
      </ProtectRoute>
    ),
  },
  {
    path: "/login",
    element: <LoginMain />,
  },
  {
    path: "/sourcetransactionreconcile",
    element: (
      <ProtectRoute>
        <SourceTransactionReconcile />
      </ProtectRoute>
    ),
  },
  {
    path: "/rejectedsourcetransaction",
    element: (
      <ProtectRoute>
        <RejectTransaction />
      </ProtectRoute>
    ),
  },
  {
    path: "/amlxtransactionreconcile",
    element: (
      <ProtectRoute>
        <AMLXTransaction />
      </ProtectRoute>
    ),
  },
  {
    path: "/amlxtransactionmapping",
    element: (
      <ProtectRoute>
        <AMLXTransactionMapping />
      </ProtectRoute>
    ),
  },
  {
    path: "/amlxtransactionmismatched",
    element: (
      <ProtectRoute>
        <AMLXMismatch />
      </ProtectRoute>
    ),
  },
  {
    path: "/amlxtransactionreport",
    element: (
      <ProtectRoute>
        <AMLXTran />
      </ProtectRoute>
    ),
  },
  {
    path: "/amlxtransactionlistbystatus",
    element: (
      <ProtectRoute>
        <AMLXTransactionByStatus />
      </ProtectRoute>
    ),
  },
  {
    path: "/xmltransactionreconcile",
    element: (
      <ProtectRoute>
        <XMLtransactionreconcile />
      </ProtectRoute>
    ),
  },
  {
    path: "/xmlReportMismatch",
    element: (
      <ProtectRoute>
        <XMLReportMisMatch />
      </ProtectRoute>
    ),
  },
  {
    path: "/authen/callback",
    element: <AuthCallback />,
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#7A58BF",
    },
    secondary: {
      main: "#462279",
    },
  },
});

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    FunctionGetTokenLogin();
  }, [location]);

  const FunctionGetTokenLogin = async () => {
    if (location) {
      const isIncludedSharp = location.hash.includes("#");
      const isIncludedLogin = location.hash.includes("login");
      const thisCondition =
        isIncludedSharp && isIncludedLogin && location?.pathname === "/";

      if (thisCondition) {
        const splitCharf = location.hash.split("#")?.[1]?.split("login");
        const params = new URLSearchParams(splitCharf?.[1]);

        if (!params.get("code")) {
          alert("Code is not found");
          return;
        }

        if (!params.get("id")) {
          alert("User Id is not found");
          return;
        }

        const code = params.get("code");
        const id = params.get("id");
        if (code && id) {
          sessionStorage.setItem("token-login", code);
          sessionStorage.setItem("token-state", code);
          sessionStorage.setItem("user_id-login", id);
          navigate("/login");
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <RefreshToken />
      <NavbarMain />
      <Routes>
        {routes.map((row, i) => {
          return (
            <Route
              key={`route-menu-key-${i}`}
              path={row.path}
              element={row.element}
            />
          );
        })}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
