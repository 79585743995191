import { useLocation, useNavigate } from "react-router-dom";
import LabelValue from "../../components/display/labelValue";
import TableMain from "../../components/table/Table.Main";
import useQuery from "../../hooks/useQuery";
import dayjs from "dayjs";
import AMLXAPI from "../../api/amlxTrans";
import AMLXError from "../../components/amlxError/amlxError";
import Loading from "../../components/loading/loading";
import { useState } from "react";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";
import PaginationMain from "../../components/pagination/Pagination.Main";
const ITEM_PER_PAGE = 10;

const AMLXTransactionByStatus = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { reportDate, reportType, status, dateType } = state;
  const [sort, setSort] = useState("");

  const mapSortKey = (key) => {
    if (key === "report_type") return "reportTypeId";
    if (key === "report_id") return "reportId";
    if (key === "branch") return "branch";
    if (key === "update_date") return "timestamp";
    return "";
  };
  const [sortDic, setSortDic] = useState({
    report_type: DEFAULT_SORT_TYPE,
    report_id: DEFAULT_SORT_TYPE,
    branch: DEFAULT_SORT_TYPE,
    update_date: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" });
      setSort({ key: key, order: "DESC" });
    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" });
      setSort({ key: key, order: "ASC" });
    }
  };

  const { data, loading, error, pagination } = useQuery(
    AMLXAPI.amlxTransByStatus,
    {
      report_date: reportDate,
      report_type: reportType,
      status: status,
      date_type: dateType === "Import Date" ? "I" : "T",
      pageIndex: page,
      recordPerPage: ITEM_PER_PAGE,
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
      //   order_by:  mapSortKey(sort?.key),
      //   sort_by: sort?.order,
    },
    {},
    [state, sort, page]
  );

  if (loading) return <Loading />;

  return (
    <>
      {error && <AMLXError error={error} />}
      <div className="p-8">
        <div className="grid grid-cols-4 gap-8 mb-8">
          <div className="col-span-4">
            <LabelValue label="Date Type" value={dateType} />
          </div>
          <LabelValue
            label="Date"
            value={dayjs(reportDate).format("DD/MM/YYYY")}
          />
          <LabelValue label="Report Type" value={reportType} />
          <LabelValue label="Status" value={status} />

          <div className="flex flex-row justify-end">
            <button
              onClick={() => navigate(-1)}
              className="bg-scbPrimary w-1/2 hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto"
            >
              Back
            </button>
          </div>
        </div>
        <TableMain
          dic={sortDic}
          sort={sort}
          setSort={onSort}
          headers={status === "DELETE" ? headerDelete : headerNomal}
          dataCells={FormatDataTable(data)}
        />
        <div className="h-6"></div>
        {pagination?.total_pages > 1 &&
          <PaginationMain
            totalRecords={pagination?.total_records}
            value={page}
            totalPage={pagination?.total_pages}
            setValue={setPage}
          />}
      </div></>
  );
};

const headerNomal = [
  { label: "ลำดับที่", key: "no" },
  { label: "Report Type", key: "report_type" },
  { label: "Report Id", key: "report_id" },
  { label: "Branch", key: "branch" },
  { label: "Last Update Date", key: "update_date" },
];
const headerDelete = [
  { label: "ลำดับที่", key: "no" },
  { label: "Report Type", key: "report_type" },
  { label: "Report Id", key: "report_id" },
  { label: "Branch", key: "branch" },
  { label: "Last Update Date", key: "update_date" },
  { label: "Delete User", key: "delete_user" },
  { label: "Delete Reason", key: "delete_reason" },
];

const FormatDataTable = (data = []) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      let object = {
        ...item,
        update_date: dayjs(item.update_date).format("DD/MM/YYYY HH:mm:ss"),
      };
      return object;
    });
    return result;
  } else {
    return [];
  }
};

export default AMLXTransactionByStatus;
