import React from "react";
import amlxAuth from "../../api/auth";
import { saveTokenToLocalStorage } from "../../utils/useFunctions";
import userStore from "../../store/userStore";

const SystemsRefreshToken = ({}) => {
  const [checkTime, setCheckTime] = React.useState(false);
  // const setUser = userStore((state) => state.setUser);

  React.useEffect(() => {
    let timer = setTimeout(async () => {
      setCheckTime(!checkTime);
      const now = Date.now();
      const plan_refresh_token_expired_at = sessionStorage.getItem(
        "plan_refresh_token_expired_at"
      );
      const actual_refresh_token_expired_at =
        sessionStorage.getItem("refresh_expired_at");

      if (
        now >= parseInt(plan_refresh_token_expired_at) &&
        now < actual_refresh_token_expired_at
      ) {
        const refresh_token = sessionStorage.getItem("refresh_token");

        const {
          data: { data },
        } = await amlxAuth.amlxRefreshToken(refresh_token);
        saveTokenToLocalStorage(data);
      }
    }, 1000 * 30);

    return () => {
      clearTimeout(timer);
    };
  }, [checkTime]);

  return <React.Fragment />;
};

export default SystemsRefreshToken;
