// import { Controllers } from "radium-omnibus";
// import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import ModalMain from "../../components/modal/Modal.Main";
// import { STYLE_INPUT, STYLE_INPUT_LOGIN } from "../../utils/useStyle";
// import LoginOnSubmit from "./functions/Login.OnSubmit";

// const LoginMain = () => {
//   const location = useLocation();
//   const [openError, setOpenError] = React.useState(false);
//   const [hasError, setHasError] = React.useState(false);
//   const [openConfirm, setOpenConfirm] = React.useState(false);

//   const navigate = useNavigate();

//   return (
//     <div
//       style={{
//         backgroundImage: "url(/images/bglogin.png)",
//       }}
//       className="bg-red-500 h-screen w-screen m-auto pt-12"
//     >
//       <div className="flex mx-auto justify-center">
//         <img src="/logo.png" className="w-24 h-24 my-auto mr-4"></img>
//         <div className="text-scbPrimary">
//           <div className="text-70px font-medium">SIAM COMMERCIAL BANK</div>
//           <div className="text-46px font-light">
//             Operation Risk Data Processing (ORDP)
//           </div>
//         </div>
//       </div>
//       <img className="mx-auto mt-12" src="images/hero.png"></img>
//       <Controllers
//         showError={false}
//         showErrorAlert={false}
//         showSymbol={false}
//         classNameSuperCore="w-640px mx-auto mt-12 text-34px space-y-4"
//         fields={[
//           {
//             name: "username",
//             label: "",
//             type: "text",
//             required: true,
//             placeholder: "Username",
//             subLabel: "*",
//             classNameSubLabel: "text-red-500 absolute right-4 top-4 ",
//             classNameWrapper: "relative",
//             classNameInput: STYLE_INPUT_LOGIN,
//           },
//           {
//             name: "password",
//             placeholder: "Password",
//             label: "",
//             type: "password",
//             required: true,
//             classNameSubLabel: "text-red-500 absolute right-4 top-4 ",
//             classNameWrapper: "relative",
//             subLabel: "*",
//             classNameInput: STYLE_INPUT_LOGIN,
//             classNameIcon: "hidden",
//           },
//           {
//             type: "submit",
//             classNameSubmit:
//               "bg-scbPrimary hover:bg-violet-800 text-white py-2 px-4 rounded text-center w-full mt-4",
//             onSubmit: (data) => {
//               LoginOnSubmit(
//                 data,
//                 setOpenError,
//                 setHasError,
//                 setOpenConfirm,
//                 navigate
//               );
//             },
//             labelSubmit: "SIGN IN",
//           },
//         ]}
//       />
//       <div className="absolute right-10 bottom-10 text-30px text-scbPrimary"> V.1.0.0.0</div>

//       <ModalMain
//         open={openError}
//         setOpen={setOpenError}
//         maxWidth="max-w-2xl"
//         styleModal={{
//           height: "150px",
//           width: "200",
//         }}
//         body={
//           <div className="text-center text-32px">
//             <img src="/icon/x.png" className="w-16 h-16 mx-auto"></img>
//             <div className="text-32px mt-2">Error!</div>
//             <div className="py-3 mb-10">Authenication failed</div>
//             <div
//               className="bg-scbPrimary rounded-xl cursor-pointer w-130px mx-auto mt-6 text-24px h-12 text-white"
//               onClick={() => {
//                 setOpenError(false);
//               }}
//             >
//               CLOSE
//             </div>
//           </div>
//         }
//         footer={false}
//       />
//     </div>
//   );
// };

// export default LoginMain;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import amlxAuth from "../../api/auth";
import userStore from "../../store/userStore";
import { parseJwt, saveTokenToLocalStorage } from "../../utils/useFunctions";
import Loading from "../../components/loading/loading";

const LoginMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const setUser = userStore((state) => state.setUser);
  const user = userStore((state) => state.user);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    FunctionLoginFromToken();
  }, [location]);

  const FunctionLoginFromToken = async () => {
    const token = sessionStorage.getItem("token-login");
    const state = sessionStorage.getItem("token-state");
    const user_id = sessionStorage.getItem("user_id-login");

    if (token) {
      const {
        data: { data },
      } = await amlxAuth.amlxLogin(token, state, user_id);

      if (data) {
        sessionStorage.setItem("access_token", data?.access_token);
        sessionStorage.setItem("refresh_token", data?.refresh_token);
        const { data: dataUser } = await amlxAuth.amlxUser(user_id);
        setLoading(false)

        setUser({ ...dataUser?.data, ...data });
        saveTokenToLocalStorage(data);
        sessionStorage.setItem("logout", "false");
        sessionStorage.removeItem("token-login");
        navigate("/");
      }
    } else {
      setUser(null);
      setLoading(false)
      const encodedUrl = encodeURIComponent(`${window.location.origin}/authen/callback`)
      window.location.replace(`${window.location.origin}/api/v1/auth/login?redirect_url=${encodedUrl}`)
    }
  };

  if(loading) return <Loading/>

  // return <div></div>

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Loading />
    </div>
  );

  // const isLogout = sessionStorage.getItem("logout") === "true";
  // if (isLogout) {
  //   return (
  //     <React.Fragment>
  //       <div className="w-screen h-screen ">
  //         <div className=" m-10 rounded-md p-10">
  //           {/* <Lottie
  //             loop={false}
  //             animationData={LottieLogout}
  //             className={"h-72"}
  //           /> */}
  //           <div className="text-center">Logout successful</div>
  //         </div>
  //       </div>
  //     </React.Fragment>
  //   );
  // } else if (!user) {
  //   return <React.Fragment></React.Fragment>;
  // } else {
  //   return (
  //     <div className="w-screen h-screen ">
  //       <div className="border border-scbPrimary m-10 rounded-md p-10">
  //         <div
  //           className="text-center"
  //           style={{
  //             fontSize: "100px",
  //           }}
  //         >
  //           401
  //         </div>
  //         <div className="text-center">
  //           You are not authorized for selected action
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
};

export default LoginMain;
