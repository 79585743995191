import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BasicDatePicker from "../../components/datePicker/datePicker";
import dayjs from "dayjs";
import TableMain from "../../components/table/Table.Main";
import AMLXAPI from "../../api/amlxTrans";
import Loading from "../../components/loading/loading";
import useQuery from "../../hooks/useQuery";
import AMLXError from "../../components/amlxError/amlxError";
import { DEFAULT_SORT_TYPE } from "../../config/configCommon";

const AMLXTransaction = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDate, setFromDate] = useState(
    searchParams.get("fromDate") ? dayjs(searchParams.get("fromDate")) : dayjs()
  );
  const [toDate, setToDate] = useState(
    searchParams.get("toDate") ? dayjs(searchParams.get("toDate")) : dayjs()
  );
  const [dateType, setDateType] = useState(searchParams.get("dateType") || "I");
  const [dateTypeCommited, setDateTypeCommited] = useState(searchParams.get("dateType") || "I");

  const [reload, setReload] = useState(0);
  const navigate = useNavigate();
  const [sort, setSort] = useState("");

  const mapSortKey = (key) => {
    if (key === "source_system") return "sourceSystem";
    if (key === "total_records") return "totalRecords";
    if (key === "total1_01") return "total101";
    if (key === "unique1_01") return "unique101";
    if (key === "total1_02") return "total102";
    if (key === "unique1_02") return "unique102";
    if (key === "total1_05_9") return "total1059";
    if (key === "unique1_05_9") return "unique1059";
    return "";
  };

  const [sortDic, setSortDic] = useState({
    source_system: DEFAULT_SORT_TYPE,
    total_records: DEFAULT_SORT_TYPE,
    total1_01: DEFAULT_SORT_TYPE,
    unique1_01: DEFAULT_SORT_TYPE,
    total1_02: DEFAULT_SORT_TYPE,
    unique1_02: DEFAULT_SORT_TYPE,
    total1_05_9: DEFAULT_SORT_TYPE,
    unique1_05_9: DEFAULT_SORT_TYPE,
  });

  const onSort = (key) => {
    if (sortDic[key] === "ASC") {
      setSortDic({ ...sortDic, [key]: "DESC" });
      setSort({ key: key, order: "DESC" });
    } else if (sortDic[key] === "DESC") {
      setSortDic({ ...sortDic, [key]: "ASC" });
      setSort({ key: key, order: "ASC" });
    }
  };

  const amlxSumary = useQuery(
    AMLXAPI.amlxSumary,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      date_type: dateType,

    },
    {},
    [reload],
  
  );

  const amlxRecoline = useQuery(
    AMLXAPI.amlxRecoline,
    {
      from_date: dayjs(fromDate).format("YYYY-MM-DD"),
      to_date: dayjs(toDate).format("YYYY-MM-DD"),
      date_type: dateType,
      ...(sort?.key && { order_by: mapSortKey(sort?.key) }),
      ...(sort?.order && { sort_by: sort?.order }),
    },
    {},
    [reload,sort]
  );

  const onClickRow = (dataCell, key) => {
    // console.log("data cell " + key,dataCell)
    if (key === "source_system" || key === "total_records") return;
    let type = "1-01";
    if (key === "total1_02" || key === "unique1_02") type = "1-02";
    if (key === "total1_05_9" || key === "unique1_05_9") type = "1-05-9";

    let total = 0;
    let unique = 0;
    if (key === "total1_01" || key === "unique1_01") {
      total = dataCell?.total1_01;
      unique = dataCell?.unique1_01;
    }
    if (key === "total1_02" || key === "unique1_02") {
      total = dataCell?.total1_02;
      unique = dataCell?.unique1_02;
    }
    if (key === "total1_05_9" || key === "unique1_05_9") {
      total = dataCell?.total1_05_9;
      unique = dataCell?.unique1_05_9;
    }
    navigate("/amlxtransactionmapping", {
      state: {
        sourceId: dataCell?.source_system,
        sourceIdRaw: dataCell?.source_id,

        reportType: type,
        dateType: dateTypeCommited === "I" ? "Import Date" : "Transaction Date",

        fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
        toDate: dayjs(toDate).format("YYYY-MM-DD"),
        fromDateFormated: dayjs(fromDate).format("DD/MM/YYYY"),
        toDateFormated: dayjs(toDate).format("DD/MM/YYYY"),
        total: total,
        unique: unique,
      },
    });
  };

  const onClickFooter = () => {
    navigate("/amlxtransactionmismatched", {
      state: {
        dateType: dateTypeCommited === "I" ? "Import Date" : "Transaction Date",
        fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
        toDate: dayjs(toDate).format("YYYY-MM-DD"),
        fromDateFormated: dayjs(fromDate).format("DD/MM/YYYY"),
        toDateFormated: dayjs(toDate).format("DD/MM/YYYY"),
      },
    });
  };

  const validate = () => {
    if (!fromDate || !toDate) {
      alert("กรุณาเลือกวันที่");
      return false;
    }
    if (!fromDate instanceof Date || isNaN(fromDate)) {
      alert("กรุณากรอกวันที่เริ่มต้นให้ถูกต้องตามรูปแบบ");
    }
    if (!toDate instanceof Date || isNaN(toDate)) {
      alert("กรุณากรอกวันที่สิ้นสุดให้ถูกต้องตามรูปแบบ");
    }
    if (fromDate !== toDate &&  dayjs(fromDate).isAfter(dayjs(toDate))) {
      alert("วันที่เริ่มต้นต้องไม่เกินวันที่สิ้นสุด");
      return false;
    }
    setSearchParams({
      fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
      toDate: dayjs(toDate).format("YYYY-MM-DD"),
      dateType,
    });
    setDateTypeCommited(dateType);
    setReload(reload + 1);
  };


  if (amlxRecoline.loading || amlxSumary.loading) return <Loading />;
  return (
    <>
      {(amlxRecoline.error || amlxSumary.error) && <AMLXError error={amlxRecoline.error || amlxSumary.error} />}
      <div className="p-8">
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <div className="text-gray-600 text-lg my-auto mr-4"> Date Type </div>
          <FormControlLabel
            value="I"
            control={
              <Radio
                onChange={(event) => setDateType(event.target.value)}
                checked={dateType === "I"}
              />
            }
            label={<div className="text-gray-600 my-auto">Import Date</div>}
          />
          <FormControlLabel
            value="T"
            control={
              <Radio
                onChange={(event) => setDateType(event.target.value)}
                checked={dateType === "T"}
              />
            }
            label={<div className="text-gray-600 my-auto">Transaction Date</div>}
          />
        </RadioGroup>
        <div className="flex flex-row justify-between  my-8 ">
          <BasicDatePicker label="From Date" value={fromDate} setValue={setFromDate} />
          <BasicDatePicker
            value={toDate}
            setValue={setToDate}
            label="To Date"
          />
          <div />
          <button
            onClick={validate}
            className="bg-scbPrimary hover:bg-scbSecondary text-white font-bold rounded-lg px-10 py-3  my-auto"
          >
            Search
          </button>
        </div>
        <TableMain
          dic={sortDic}
          sort={sort}
          setSort={onSort}
          onClickRow={onClickRow}
          colAllowedOnClick={["total1_01", "unique1_01", "total1_02", "unique1_02", "total1_05_9", "unique1_05_9"]}
          headers={[
            { label: "Source System", key: "source_system" },
            { label: "Total Complete", key: "total_records" },
            { label: "Total 1-01", key: "total1_01" },
            { label: "Unique 1-01", key: "unique1_01" },
            { label: "Total 1-02", key: "total1_02" },
            { label: "Unique 1-02", key: "unique1_02" },
            { label: "Total 1-05-9", key: "total1_05_9" },
            { label: "Unique 1-05-9", key: "unique1_05_9" },
          ]}
          dataCells={amlxRecoline?.data}
          footer={
            <tfoot className="border-t border-gray-300">
              <tr>
                <td className="py-3  px-6 text-left text-lg font-semibold text-textscb ">
                  Source Total
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>

                <td className="py-3  px-6 text-center text-lg font-semibold text-gray-500 ">
                  {amlxSumary?.data?.source?.unique1_01}
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>

                <td className="py-3  px-6 text-center text-lg font-semibold text-gray-500 ">
                  {amlxSumary?.data?.source?.unique1_02}
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>
                <td className="py-3  px-6 text-center text-lg font-semibold text-gray-500 ">
                  {amlxSumary?.data?.source?.unique1_05_9}
                </td>
              </tr>
              <tr>
                <td className="py-3  px-6 text-left text-lg font-semibold text-textscb ">
                  Report Master
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>

                <td className="py-3  px-6 text-center text-lg font-semibold text-gray-500 ">
                  {amlxSumary?.data?.report?.unique1_01}
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>

                <td className="py-3  px-6 text-center text-lg font-semibold text-gray-500 ">
                  {amlxSumary?.data?.report?.unique1_02}
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>
                <td className="py-3  px-6 text-center text-lg font-semibold text-gray-500 ">
                  {amlxSumary?.data?.report?.unique1_05_9}
                </td>
              </tr>
              <tr className="border-t border-gray-300">
                <td className="py-3  px-6 text-left text-lg font-semibold text-textscb ">
                  Differences
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>

                <td
                  onClick={onClickFooter}
                  className={"py-3 cursor-pointer px-6 text-center text-lg font-semibold " + ((amlxSumary?.data?.source?.unique1_01 -
                    amlxSumary?.data?.report?.unique1_01) != 0 ? "text-red-500" : "text-gray-500")}
                >
                  {amlxSumary?.data?.source?.unique1_01 -
                    amlxSumary?.data?.report?.unique1_01}
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>

                <td
                  onClick={onClickFooter}
                  className={"py-3 cursor-pointer px-6 text-center text-lg font-semibold " + ((amlxSumary?.data?.source?.unique1_02 -
                    amlxSumary?.data?.report?.unique1_02) != 0 ? "text-red-500" : "text-gray-500")}              >
                  {amlxSumary?.data?.source?.unique1_02 -
                    amlxSumary?.data?.report?.unique1_02}
                </td>
                <td className="py-3  px-6 text-left text-lg font-semibold text-scbPrimary "></td>
                <td
                  onClick={onClickFooter}
                  className={"py-3 cursor-pointer px-6 text-center text-lg font-semibold " + ((amlxSumary?.data?.source?.unique1_05_9 -
                    amlxSumary?.data?.report?.unique1_05_9) != 0 ? "text-red-500" : "text-gray-500")}              >
                  {amlxSumary?.data?.source?.unique1_05_9 -
                    amlxSumary?.data?.report?.unique1_05_9}
                </td>
              </tr>
            </tfoot>
          }
        />
      </div>
    </>
  );
};

export default AMLXTransaction;
