import React from "react";
import { Alert, AlertTitle, Collapse } from "@mui/material";

function AMLXError({ error, mt = "0" }) {
  const [open, setOpen] = React.useState(true);

  console.log("error ",error)

  return (
    <div className={"w-full"}>
      <Collapse in={open}>
        <Alert onClose={() => setOpen(false)} severity="error">
          <AlertTitle>Server Error</AlertTitle>
          {error?.message}
        </Alert>

      </Collapse>
    </div>
  );
}

export default AMLXError;