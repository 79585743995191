import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ICON_ARROW_DOWN } from "../../../utils/useIcons";
import userStore from "../../../store/userStore";
import amlxAuth from "../../../api/auth";
import dayjs from "dayjs";
import ModalMain from "../../modal/Modal.Main";
import { Logout } from "@mui/icons-material";

const NavbarModalUser = ({ name, oc, lastLogin, version }) => {
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const navigate = useNavigate();
  const wrapper = React.useRef(null);
  const { setUser } = userStore.getState();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const logOut = async()=>{
      const refresh_token =
        sessionStorage.getItem("refresh_token");
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("refresh_token");
      sessionStorage.setItem("logout", "true");
      setUser(null);
      if (refresh_token) {
        await amlxAuth.amlxLogout(refresh_token);
      }
      navigate("/login");
      setOpen(false);
  }

  useOutsideAlerter(wrapper);

  return (
  
    <div className=" z-20 my-auto text-white font-medium">
      <div className="text-xl mr-8">{name}</div>
      <div className=" flex justify-end text-xl">
        <div>oc : {oc}</div>
        <div className="">
          <ICON_ARROW_DOWN
            onClick={() => {
              setOpen(!open);
            }}
            className={`${
              open ? " rotate-180 " : " rotate-0 "
            } transition-all duration-300 cursor-pointer -mt-[10px]`}
          />

          <div
            // style={{
            //   opacity: open ? 1 : 0,
            // }}
            className={`absolute right-24`}
          >
            {open && (
              <div
                ref={wrapper}
                className="bg-white  bg-opacity-100 text-black text-lg py-3 px-5 text-left rounded-lg shadow-xl"
              >
                <div>LAST LOGON : {dayjs(lastLogin).format("DD/MM/YYYY HH:mm:ss")}</div>
                <div>VERSION : {version}</div>
                {/* <div className="border border-b-gray-400 my-4" /> */}
                <button
                  onClick={()=>setOpenConfirm(true)}
                  className="text-xl text-scbPrimary flex justify-start w-full mt-8"
                >
                  LOG OUT
                  <img
                    src="/icon/log-out.png"
                    className="w-7 h-7 ml-4 mt-1"
                  ></img>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalMain
        onClickSubmit={logOut}
        open={openConfirm}
        setOpen={setOpenConfirm}
        title="ยืนยันการออกระบบ ?"
        maxWidth = " max-w-xl"
        labelSubmit="OK"
        labelCancel="Cancel"
      />
    </div>
  );
};
export default NavbarModalUser;
